
import { defineComponent, onMounted, reactive } from "vue";
import { ethitransService } from "@/services/EthitransService";
import { common } from "@/common/services/Common";

import ErrorList from "@/components/commonDesign/ErrorList.vue";

export default defineComponent({
  name: "NotificationModal",
  components: {
    ErrorList,
  },
  props : {
      showModal : Boolean
  },
  setup(props, { emit }) {
    // const router = useRouter();
    const state = reactive({
      countryCodeList: [] as any,
      data: {
        timeCount:130,
         phone: {
          mobileCode: "+1",
          otp: "" as any,
          isVerify: false,
        },
         registration: {
          mobile: "",
        },
        notification: {
          sms: false,
          email: false,
        },
      },
      activeUserDetails: {} as any,
      ui: {
        error: {
          errorsList: [] as any,
          hasError: false,
        },
        isLoading: {
          clientDocumentRetrieveWorkflow: false,
        },
        isPostLoading: {
          updateUserNotification: false,
        },
        inputOtp: false,
        sendOtpButton: true,
         verifyButton:true,
      },
    });
    function getUserData(){
    const userDetails: any = localStorage.getItem("current_user_info");
    console.log('heyim om',userDetails);
      if (userDetails) {
        state.activeUserDetails = JSON.parse(userDetails);
      }
      // console.log("Data Check >>>", state.activeUserDetails);
      if (state.activeUserDetails) {
         state.data.notification.email=state.activeUserDetails.email_notification;
         state.data.notification.sms=state.activeUserDetails.sms_notification;
       state.data.phone.mobileCode = state.activeUserDetails.dial_code_mobile?state.activeUserDetails.dial_code_mobile:"+1";
       state.data.registration.mobile= state.activeUserDetails.mobile_number;
       state.data.phone.isVerify = state.activeUserDetails.is_mobile_verified;
      }

    }
    function closeSuccessfulModal() {
      emit("close");
      $("#notificationModal").modal("hide");
    }


 function getCountryCodeList() {
      console.log("getprojectList WOWO:>>>");
      const payLoad = {};
      ethitransService
        .getCountryCodeList(payLoad)
        .then((res: any) => {
          console.log("getprojectList Check:>>> ", res.data.data);
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.selectedFormForEdit = res.data ? res.data : {};
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }
    function countDownTimer() {
      const interval = setInterval(() => {
        if (state.data.timeCount === 0) {
          state.ui.sendOtpButton = true;
          state.ui.inputOtp = false;
          clearInterval(interval);
          state.data.timeCount = 130;
          // state.data.phone.code = state.data.phone.code;
          // getCountryCodeList();
        } else {
          state.data.timeCount--;
        }
      }, 1000);
    }

    function sendOtp() {
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        client: state.data.registration.mobile,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
      };
      state.ui.verifyButton=false,
      ethitransService
        .sendOtp(payLoad)
        .then((res: any) => {
          console.log("meesaom", res.code);

          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.data.phone.code = res.data.data.dial_code;
          state.ui.inputOtp = true;
          state.ui.sendOtpButton = false;
          getCountryCodeList();
          countDownTimer();
                        state.ui.error.errorsList = [];

          // interval;
        })
        .catch((error: any) => {
          console.log(error);
            common
            .sdCatchErr(error,   state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.errorsList = [];
               state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
            });
          // state.ui.error.hasError = true;
          // state.ui.error.validationList = [];
          // state.ui.error.validationList.push(
          //   "pleae check your mobile number or country code."
          // );
        })
        .finally(() => {
        state.ui.verifyButton=true;
        });
    }

    function verifyMobile() {
      state.ui.error.hasError = false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.registration.mobile,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
        otp: state.data.phone.otp,
      };
      ethitransService
        .verifyMobile(payLoad)
        .then((res: any) => {
          state.countryCodeList = res.data.data ? res.data.data : [];
          // state.data.phone.mobileCode = state.data.phone.mobileCode;
          getCountryCodeList();
          if (res.data.data.is_verify == true) {
            state.data.phone.isVerify = true;
            state.ui.inputOtp = false;
            state.ui.error.hasError = false;
          } else {
            state.data.phone.isVerify = false;
            state.ui.error.hasError = true;
            state.ui.error.errorsList = [];
            state.ui.error.errorsList.push("OTP didnot match.");
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          // state.ui.postLoading.getprojectList = false;
        });
    }

    function checkInputValue() {
      console.log("Checking input value", state.data.phone.otp.length);
      if (state.data.phone.otp.length == 5) {
        verifyMobile();
      }
    }


function validateEditProfile() {
      // state.data.showEditProfile =!state.data.showEditProfile;
      // console.log("Validate Edit Profile");
      if (!state.data.registration.mobile && state.data.notification.sms) {
        state.ui.error.errorsList.push("Mobile number is required.");
      }
       if (state.data.registration.mobile && state.data.notification.sms && !state.data.phone.isVerify) {
        state.ui.error.errorsList.push("Mobile number verification is required.");
      }

       if (state.data.registration.mobile && state.data.notification.sms && !state.data.phone.isVerify && !state.data.phone.otp) {
        state.ui.error.errorsList.push("please enter OTP .");
      }
      
    }

    function updateUserNotification() {
      state.ui.error.hasError = true;
      state.ui.error.errorsList = []
       validateEditProfile();
         if (
        state.ui.error.errorsList.length      )
        return false;
      const payLoad = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        email_notification: state.data.notification.email ? 1 : 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sms_notification: state.data.notification.sms ? 1 : 0,
        // eslint-disable-next-line @typescript-eslint/camelcase
        mobile_number: state.data.registration.mobile,
        // eslint-disable-next-line @typescript-eslint/camelcase
        dial_code_mobile: state.data.phone.mobileCode,
        // eslint-disable-next-line @typescript-eslint/camelcase
         is_mobile_verified: state.data.phone.isVerify,
      };
      state.ui.isPostLoading.updateUserNotification = true;
      ethitransService
        .updateUserNotification(payLoad)
        .then((res: any) => {
          // $("#notificationModal").modal("hide");
          console.log(
            "updateUserNotification Response Check:>>> ",
            res.data.data
          );
          localStorage.setItem(
            "current_user_info",
            JSON.stringify(res.data.data)
          );
          // const userDetails: any = localStorage.getItem("current_user_info");
          // if (userDetails) {
          //   state.activeUserDetails = JSON.parse(userDetails);
          // }
          // console.log("Data Check >>>", state.activeUserDetails);
          // if (state.activeUserDetails) {
          //   if (
          //     state.activeUserDetails.email_notification === 1 ||
          //     state.activeUserDetails.email_notification === "1"
          //   ) {
          //     state.data.notification.email = true;
          //     console.log("Email 1");
          //   } else {
          //     console.log("Email 0");
          //     state.data.notification.email = false;
          //   }

          //   if (
          //     state.activeUserDetails.sms_notification === 1 ||
          //     state.activeUserDetails.sms_notification === "1"
          //   ) {
          //     console.log("SMS 1");
          //     state.data.notification.sms = true;
          //   } else {
          //     console.log("SMS 0");
          //     state.data.notification.sms = false;
          //   }
          // }
                 closeSuccessfulModal()
            state.data.registration.mobile="";
            state.data.phone.mobileCode="";
        })
        .catch((error: any) => {
          common
            .sdCatchErr(error, state.ui.error.errorsList)
            .then((res: any) => {
              state.ui.error.hasError = true;
              for (const [key, value] of Object.entries(res)) {
                if (Array.isArray(value)) {
                  state.ui.error.errorsList.push(value[0]);
                }
              }
            });
        })
        .finally(() => {
          state.ui.isPostLoading.updateUserNotification = true;
        });
    }
    onMounted(() => {
               getUserData();
        if(props.showModal){
            $("#notificationModal").modal("show");
        }
         getCountryCodeList();
      //   getFolderList();
    });

    return {
      state,
      closeSuccessfulModal, updateUserNotification,
       getCountryCodeList,
      sendOtp,
      verifyMobile,
      checkInputValue,
      countDownTimer,
      getUserData,
      validateEditProfile
    };
  },
});
